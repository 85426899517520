import React from "react"

const Loading = (props: any) => {
  return (
    <div
      className={
        props?.type === "blue"
          ? "loading-icon loadingio-spinner-rolling-bp05hhkmuvm blue"
          : "loading-icon loadingio-spinner-rolling-bp05hhkmuvm"
      }
    >
      <div className="ldio-hml4x2fgv0i">
        <div />
      </div>
    </div>
  )
}
export default Loading
